import { BrowserRouter, Routes, useNavigate, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";

import { Suspense, lazy, useEffect } from "react";
import "./App.css";

const LoginPage = lazy(() => import("./pages/Login.js"));
const VerifyPage = lazy(() => import("./pages/Verify.js"));
const RegisterPage = lazy(() => import("./pages/Register.js"));
const Loading = lazy(() => import("./pages/Loading.js"));
const Rewards = lazy(() => import("./pages/Rewards.js"));
const Upload = lazy(() => import("./pages/Upload.js"));
const Brand = lazy(() => import("./pages/Brand.js"));
const Home = lazy(() => import("./pages/Home.js"));
const Credit = lazy(() => import("./pages/Credit.js"));
const HistoryPage = lazy(() => import("./pages/History.js"));

function App() {
  return (
    <div className="App">
      {" "}
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/verify" element={<VerifyPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/rewards" element={<Rewards />} />
            <Route path="/credits" element={<Credit />} />
            <Route path="/upload" element={<Upload />} />
            <Route path="/brand" element={<Brand />} />
            <Route path="/home" element={<Home />} />
            <Route path="/history" element={<HistoryPage />} />
            {/* Default */}
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
